import { FacturaEntity } from "../../../backend/src/shared/entity";
import { getCollection } from "../common/localEntity";

export function useProcesVerbal() {
  const getProcessVerbal = (facturi?: FacturaEntity[]) =>
    (facturi || getCollection("dbo.Factura")).find(
      (f) =>
        f.ScanatLivrat + f.ScanatRefuz === f.NumarColete &&
        !f.ProcesVerbal &&
        f.ScanatLivrat
    );
  return { getProcessVerbal };
}
