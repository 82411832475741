import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCollection, getEntity, setQueue } from "../../common/localEntity";
import ErrorBox from "../../components/ErrorBox";
import Phone from "../../components/Phone";
import { StatusLivrareIcon } from "../../components/StatusLivrareIcon";
import { useUser } from "../../hooks/useUser";
import {
  ColetEntity,
  FacturaEntity,
} from "../../../../backend/src/shared/entity";
import sql from "../../common/sql";

const ColeteStatusReset = () => {
  const { idFactura } = useParams();
  const { user } = useUser();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [factura, setFactura] = useState<FacturaEntity>();
  const [colete, setColete] = useState<ColetEntity[]>([]);

  useEffect(() => {
    if (user.role === "sofer") {
      const factura = getEntity("dbo.Factura", +(idFactura || 0));
      setLoading(false);
      if (!factura) {
        return setError(
          `Factura ${idFactura} nu există sau nu este accesibilă`
        );
      }
      const colete = getCollection("dbo.Colet").filter(
        (c) => c.IdFactura === factura.Id
      );
      setFactura(factura);
      setColete(colete);
      console.log("sofer", { factura, colete });
    } else {
      sql
        .facturaDetailsQuery({ idFactura })
        .then(([[factura], colete]) => {
          setFactura(factura);
          setColete(colete);
          setLoading(false);
        })
        .catch((e) => [setError(e.message || e), console.error(e)])
        .finally(() => setLoading(false));
    }
  }, [user, idFactura, setFactura, setColete]);

  const handleStatusReset = (colet: ColetEntity) => {
    if (colet.ScanatRetur) {
      if (
        window.confirm(
          `${colet.Descriere}.\nUltimul status : returnat.\n\nDoriți să resetați statusul?`
        )
      ) {
        colet.ScanatRetur = null;
        setQueue("dbo.Colet", colet);
        setColete([...colete]);
      }
    } else if (colet.ScanatRefuz) {
      if (
        window.confirm(
          `${colet.Descriere}.\nUltimul status : refuzat.\n\nDoriți să resetați statusul?`
        )
      ) {
        colet.ScanatRefuz = null;
        setQueue("dbo.Colet", colet);
        setColete([...colete]);
      }
    } else if (colet.ScanatLivrat) {
      if (
        window.confirm(
          `${colet.Descriere}.\nUltimul status : livrat.\n\nDoriți să resetați statusul?`
        )
      ) {
        colet.ScanatLivrat = null;
        setQueue("dbo.Colet", colet);
        setColete([...colete]);
      }
    } else if (colet.ScanatCamion) {
      if (
        window.confirm(
          `${colet.Descriere}.\nUltimul status : încărcat camion.\n\nDoriți să resetați statusul?`
        )
      ) {
        colet.ScanatCamion = null;
        setQueue("dbo.Colet", colet);
        setColete([...colete]);
      }
    } else if (colet.ScanatPalet) {
      if (
        window.confirm(
          `${colet.Descriere}.\nUltimul status : palet ${colet.IndexPalet}.\n\nDoriți să resetați statusul?`
        )
      ) {
        colet.ScanatPalet = null;
        setQueue("dbo.Colet", colet);
        setColete([...colete]);
      }
    } else if (colet.ScanatLiber) {
      if (
        window.confirm(
          `${colet.Descriere}.\nUltimul status : colet liber.\n\nDoriți să resetați statusul?`
        )
      ) {
        colet.ScanatLiber = null;
        setQueue("dbo.Colet", colet);
        setColete([...colete]);
      }
    }
  };

  if (loading) return <progress />;
  if (error) return <ErrorBox>{error}</ErrorBox>;
  if (!factura)
    return <ErrorBox>Factura nu există sau nu este accesibilă</ErrorBox>;

  return (
    <section>
      <h6 className="text-muted text-end mt-4">
        <span>Colete din factura</span> {factura.Document} <br />
        <small>
          {factura.Cumparator} <Phone>{factura.Telefon}</Phone>
        </small>
        <hr />
      </h6>
      {colete?.map((colet) => (
        <p key={colet.Id} onClick={(e) => handleStatusReset(colet)}>
          <StatusLivrareIcon {...colet} /> {colet.Descriere}
        </p>
      ))}
    </section>
  );
};

export default ColeteStatusReset;
