import { useEffect, useState } from "react";
import ErrorBox from "./ErrorBox";
import { ColetEntity, DosarEntity, FacturaEntity } from "../../../backend/src/shared/entity";
import sql from "../common/sql";
import { Progress } from "./StatusLivrare";
import { Link } from "react-router-dom";
import { ReturIcon } from "./StatusIcons";
import { toast } from "sonner";
import { publish } from "../common/events";

const DepozitReturConsumer = ({ barcode = "" }) => {
  const [error, setError] = useState("");
  const [dosar, setDosar] = useState<DosarEntity>();
  const [colet, setColet] = useState<ColetEntity>();
  const [colete, setColete] = useState<ColetEntity[]>();
  const [oldcode, setOldCode] = useState("");

  console.log("DepozitReturConsumer", { barcode, oldcode, colet, colete });

  if (colet?.Id && colete?.length && !colete?.includes(colet)) {
    // coletul a fost modificat, scris și recitit din baza de date
    // trebuie să-l actualizez și în listă
    setColete((colete) => colete?.map((c) => (c.Id === colet.Id ? colet : c)));
  }
  // derived state
  const coletCount = colete?.filter((c) => c.ScanatRefuz).length || 0;
  const returCount = colete?.filter((c) => c.ScanatRetur).length || 0;
  // handle events
  const handleReturColet = (colet?: ColetEntity) => {
    if (!colet) return;
    sql
      .saveEntity("dbo.Colet", {
        ...colet,
        ScanatRetur: new Date(),
      })
      .then(setColet)
      .then(() =>
        toast.success(`Coletul "${colet.Descriere}" a fost marcat "Retur"!`)
      )
      .then(() => publish("ColetChanged", colet))
      .catch(setError);
  };
  if (!barcode && error) setError("");
  if (barcode && barcode !== oldcode) {
    setOldCode(barcode);
    setError("");
    sql // caut dosarul aferent coletului scanat, el trebuie sa fie marcat ca paletat
      .coleteByBarcodeQuery({ barcode })
      .then(([[dosar],, colete]: [DosarEntity[], FacturaEntity, ColetEntity[]]) => {
        if (!dosar) {
          setError("Nu am găsit dosar pentru codul de bare scanat!");
          toast.error("Nu am găsit dosar pentru codul de bare scanat!");
        } else if (!dosar.ScanatRefuz) {
          setError(`Dosarul "${dosar.Nume}" nu are colete marcate "Refuzat"!`);
          toast.error(
            `Dosarul "${dosar.Nume}" nu are colete marcate "Refuzat"!`
          );
        } else if (dosar.ScanatRefuz) {
          const colet = colete.find((c) => c.IdSursa === +barcode);
          if (colet?.ScanatRefuz) {
            handleReturColet(colet);
          } else {
            return alert(
              `Coletul ${colet?.Descriere} nu este marcat "Refuzat"!`
            );
          }
          setDosar(dosar);
          setColete(colete);
        } else {
          setError("Ceva nu a mers bine!");
          toast.error("Ceva nu a mers bine!");
        }
      })
      .catch((err) => setError(err.message));
  }

  useEffect(() => {
    if (coletCount && coletCount === returCount)
      publish("ReturScanComplet", dosar);
  });

  if (error) return <ErrorBox>{error}</ErrorBox>;
  if (!dosar) return <h5>Scanează un colet</h5>;

  return (
    <>
      <h4 className="mb-1">
        <ReturIcon />
        &nbsp;
        <Progress total={coletCount} done={returCount} />
        <Link className="ms-2" to={`/dosar-details/${dosar?.Id}`}>
          {dosar?.Nume}
        </Link>
      </h4>
      <small className="text-muted">{colet?.Descriere}</small>
      {returCount === coletCount && (
        <p className="text-success">
          Toate colete ( {returCount}/{coletCount} ) au fost marcate "retur"!
        </p>
      )}
    </>
  );
};

export default DepozitReturConsumer;
