import { useContext, useEffect } from "react";
import { TitleContext } from "../App";

const NavbarTitle = ({ children, icon = "", iconStyle = "fa-regular" }: any) => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    if (icon) {
      setTitle(<><i className={`fa-lg ${iconStyle} ${icon} mx-2`}></i>{children}</>);
    } else {
      setTitle(children);
    }
  }, [setTitle, children, icon, iconStyle]);
  return (
    <></>
  )
};

export default NavbarTitle;
