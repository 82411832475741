import { useEffect, useState } from "react";
import ErrorBox from "./ErrorBox";
import { toast } from "sonner";
import { getCollection, setCollection, setQueue } from "../common/localEntity";
import { publish } from "../common/events";
import { useModal } from "../hooks/useModal";
import { Progress } from "./StatusLivrare";
import Ramburs from "./Ramburs";
import { LivratIcon, RefuzIcon } from "./StatusIcons";

const CamionLivratConsumer = ({ barcode = "" }) => {
  const [error, setError] = useState("");
  const [dosar] = useState(getCollection("dbo.Dosar")[0]);
  const [facturi] = useState(getCollection("dbo.Factura"));
  const [factura, setFactura] = useState(
    facturi.find(
      (f) =>
        f.ScanatLivrat + f.ScanatRefuz &&
        f.ScanatLivrat + f.ScanatRefuz < f.NumarColete
    )
  );
  const [colete, setColete] = useState(getCollection("dbo.Colet"));
  const [oldcode, setOldCode] = useState("");
  const { setModal } = useModal();
  // derived state
  const livratCount =
    colete?.filter((c) => c.IdFactura === factura?.Id && c.ScanatLivrat)
      .length || 0;
  const refuzCount =
    colete?.filter((c) => c.IdFactura === factura?.Id && c.ScanatRefuz)
      .length || 0;
  const coletCount =
    colete?.filter((c) => c.IdFactura === factura?.Id).length || 0;

  // effects
  useEffect(() => publish("ColetChanged"), [colete]);
  useEffect(() => {
    if (coletCount && coletCount === livratCount + refuzCount) {
      publish(
        "FacturaScanComplet",
        <span className="text-success">
          Toate coletele din factura {factura?.Document} au fost scanate!
        </span>
      );
    }
  }, [livratCount, refuzCount, coletCount, factura?.Document]);

  if (!(dosar && colete.length)) {
    return <h5>Dosarul nu a fost încărcat, scanează codul QR al dosarului</h5>;
  }
  if (!barcode && error) setError("");
  if (barcode && barcode !== oldcode) {
    setOldCode(barcode);
    setError("");
    const Colet = colete.find((c) => c.IdSursa === +barcode);
    const Factura = factura || facturi.find((f) => f.Id === Colet?.IdFactura);
    if (Colet?.IdFactura !== Factura?.Id) {
      let message = `Coletul ${barcode} nu face parte din dosarul ${dosar.Nume}!`;
      if (Colet && Factura) {
        message = `Coletul ${Colet.Descriere} nu face parte din factura ${Factura.Document}!`;
      }
      setError(message);
      setModal(
        <span className="text-danger">{message}</span>,
        "Scanare eronată"
      );
    } else if (Colet && Colet.ScanatLivrat) {
      let message = `Coletul ${Colet.Descriere} a fost scanat deja!`;
      toast(message);
    } else if (Colet && Colet.ScanatRefuz) {
      if (
        window.confirm(
          `Coletul ${Colet.Descriere} este marcat "refuzat". Doriți să-l marcați "livrat"?`
        )
      ) {
        setQueue("dbo.Colet", {
          ...Colet,
          ScanatLivrat: new Date(),
          ScanatRefuz: null,
        });
        const colete = getCollection("dbo.Colet");
        setColete(colete);
        facturi
          .filter((f) => f.Id === Colet.IdFactura)
          .forEach((f) => {
            f.ScanatLivrat = colete.filter(
              (c) => c.ScanatLivrat && c.IdFactura === f.Id
            ).length;
            f.ScanatRefuz = colete.filter(
              (c) => c.ScanatRefuz && c.IdFactura === f.Id
            ).length;
          });
        setCollection("dbo.Factura", facturi); // update facturi din localEntity
      }
    } else if (Colet && !Colet.ScanatLivrat) {
      setQueue("dbo.Colet", { ...Colet, ScanatLivrat: new Date() });
      const colete = getCollection("dbo.Colet");
      setColete(colete);
      facturi
        .filter((f) => f.Id === Colet.IdFactura)
        .forEach(
          (f) =>
            (f.ScanatLivrat = colete.filter(
              (c) => c.ScanatLivrat && c.IdFactura === f.Id
            ).length)
        );
      setCollection("dbo.Factura", facturi); // update facturi din localEntity
    } else {
      let message = `Coletul ${barcode} nu face parte din dosarul ${dosar.Nume}!`;
      setError(message);
      toast.error(message);
    }
    if (factura !== Factura) setFactura(Factura);
  }

  return (
    <>
      {factura?.Id ? (
        <>
          <h5 className="text-end">
            <div className="justify-content-between">
              <Ramburs {...factura } />
              <span>
                <div className="justify-content-between">
                  {refuzCount ? (
                    <span>
                      <RefuzIcon />
                      <b className="text-larger ms-1">
                        <Progress done={refuzCount} total={coletCount} />
                      </b>
                    </span>
                  ) : (
                    <b></b>
                  )}
                  <span>
                    <LivratIcon /> Livrat
                    <b className="text-larger ms-1">
                      <Progress done={livratCount} total={coletCount} />
                    </b>
                  </span>
                </div>
                <div>
                  <small className="text-muted">
                    {factura?.Cumparator} - factura &nbsp;
                  </small>
                  {factura?.Document}
                </div>
              </span>
            </div>
          </h5>
          <ErrorBox>{error}</ErrorBox>
        </>
      ) : (
        <h5>Scanează un colet</h5>
      )}
    </>
  );
};

export default CamionLivratConsumer;
