import { useUser } from "../hooks/useUser";
import DosarIndexPage from "./dosar/DosarIndexPage";
import LoginPage from "./user/LoginPage";
import { useActiveRoute } from "../hooks/useActiveRoute";

const HomePage = () => {
  const { user } = useUser();
  const {setActiveRoute} = useActiveRoute();
  if (user.role === "sofer") setTimeout(setActiveRoute);
  if (user.email) return <DosarIndexPage />;
  return <LoginPage />;
};

export default HomePage;
