
import { EntityName, EntityType } from "../../../backend/src/shared/entity";
import env from "./env";

export type SqlError = { message: string, number: string | number };

export class SqlService {
  async handleResponse(response: Response) {
    let responseJson, responseText;
    const contentType = response.headers.get("content-type");
    if (contentType && ~contentType.indexOf("application/json")) {
      responseJson = await response.json();
    } else {
      responseText = await response.text();
    }
    if (response.status === 401) {
      if (window.location.pathname.includes("/login")) return;
      window.location.href = `/login/${encodeURIComponent(window.location.pathname)}/401`;
    } else if (response.status >= 400) {
      throw responseJson || responseText;
    }
    return responseJson || responseText;
  }
  async deleteEntity(
    table: EntityName,
    id: string | number
  ) {
    const response = await fetch(`${env.API_URL}/entity?table=${encodeURIComponent(table)}&id=${encodeURIComponent(id)}`, {
      method: "DELETE",
      credentials: 'include'
    });
    return this.handleResponse(response);
  }
  async saveEntity<T extends EntityName>(
    table: T,
    params: { [key: string]: string | number | Date | boolean | undefined | null }
  ): Promise<EntityType<T>> {
    const response = await fetch(`${env.API_URL}/entity`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ table, params }),
    });
    return this.handleResponse(response);
  }
  async query(
    query: string,
    params?: { [key: string]: string | number | Date | boolean | undefined | null }
  ) {
    const response = await fetch(`${env.API_URL}/query`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query, params }),
    });
    return this.handleResponse(response);
  }
  loginUserQuery(params: { email: string | number | Date | boolean | undefined | null, password: string | number | Date | boolean | undefined | null, auto: string | number | Date | boolean | undefined | null, role: string | number | Date | boolean | undefined | null }) {
    return this.query('loginUserQuery', params);
  }
  registerUserQuery(params: { name: string | number | Date | boolean | undefined | null, email: string | number | Date | boolean | undefined | null, password: string | number | Date | boolean | undefined | null, role: string | number | Date | boolean | undefined | null }) {
    return this.query('registerUserQuery', params);
  }
  passwordRecoverQuery(params: { email: string | number | Date | boolean | undefined | null }) {
    return this.query('passwordRecoverQuery', params);
  }
  validateTokenQuery(params: { token: string | number | Date | boolean | undefined | null }) {
    return this.query('validateTokenQuery', params);
  }
  loggedUserQuery() {
    return this.query('loggedUserQuery');
  }
  sumarArticoleDosareReportQuery(params: { startDate: string | number | Date | boolean | undefined | null, endDate: string | number | Date | boolean | undefined | null }) {
    return this.query('sumarArticoleDosareReportQuery', params);
  }
  facturaDetailsQuery(params: { idFactura: string | number | Date | boolean | undefined | null }) {
    return this.query('facturaDetailsQuery', params);
  }
  fulltextDosarQuery(params: { finalizat: string | number | Date | boolean | undefined | null, fullText: string | number | Date | boolean | undefined | null, calendar: string | number | Date | boolean | undefined | null }) {
    return this.query('fulltextDosarQuery', params);
  }
  dosarByIdQuery(params: { idDosar: string | number | Date | boolean | undefined | null }) {
    return this.query('dosarByIdQuery', params);
  }
  coleteByBarcodeQuery(params: { barcode: string | number | Date | boolean | undefined | null }) {
    return this.query('coleteByBarcodeQuery', params);
  }
  dosarDeleteQuery(params: { idDosar: string | number | Date | boolean | undefined | null }) {
    return this.query('dosarDeleteQuery', params);
  }
  dosarDetailsQuery(params: { idDosar: string | number | Date | boolean | undefined | null }) {
    return this.query('dosarDetailsQuery', params);
  }
  dosarResetQuery(params: { idDosar: string | number | Date | boolean | undefined | null }) {
    return this.query('dosarResetQuery', params);
  }
  dosarAdaugaFacturaQuery(params: { idFactura: string | number | Date | boolean | undefined | null, idDosar: string | number | Date | boolean | undefined | null }) {
    return this.query('dosarAdaugaFacturaQuery', params);
  }
  dosarStergeFacturaQuery(params: { idFactura: string | number | Date | boolean | undefined | null, idDosar: string | number | Date | boolean | undefined | null }) {
    return this.query('dosarStergeFacturaQuery', params);
  }
  facturiQrCodeQuery() {
    return this.query('facturiQrCodeQuery');
  }
  syncStatusListQuery() {
    return this.query('syncStatusListQuery');
  }
  syncDatabasesQuery(params: { checkOnly: string | number | Date | boolean | undefined | null }) {
    return this.query('syncDatabasesQuery', params);
  }
  
}

const sql = new SqlService()
export default sql;
