import { useState } from "react";
import LivrareFacturaItem from "./LivrareFacturaItem";
import { getCollection } from "../common/localEntity";
import { subscribe } from "../common/events";
import ProcesVerbal from "./ProcesVerbal";
import { Progress } from "./StatusLivrare";
import { RefuzIcon } from "./StatusIcons";
import { FacturaEntity } from "../../../backend/src/shared/entity";
import { useProcesVerbal } from "../hooks/useProcesVerbal";

const lipsaProcesVerbal = (a: FacturaEntity, b: FacturaEntity) => {
  if (
    a.ScanatLivrat &&
    a.ScanatLivrat + a.ScanatRefuz === a.NumarColete &&
    !a.ProcesVerbal
  ) {
    return -1;
  }
  if (
    b.ScanatLivrat &&
    b.ScanatLivrat + b.ScanatRefuz === b.NumarColete &&
    !b.ProcesVerbal
  ) {
    return 1;
  }
  if (
    a.ScanatLivrat + a.ScanatRefuz &&
    a.ScanatLivrat + a.ScanatRefuz < a.NumarColete
  ) {
    // a este factura in curs de livrare/scanare
    return -1;
  }
  if (
    b.ScanatLivrat + b.ScanatRefuz &&
    b.ScanatLivrat + b.ScanatRefuz < b.NumarColete
  ) {
    // b este factura in curs de livrare/scanare
    return 1;
  }
  if (a.ScanatLivrat + a.ScanatRefuz === a.NumarColete) {
    // a este deja livrata/finalizata
    return 1;
  }
  if (b.ScanatLivrat + b.ScanatRefuz === b.NumarColete) {
    // b este deja livrata/finalizata
    return -1;
  }
  return a.NumarOrdine < b.NumarOrdine ? -1 : 1; // după numarul de ordine din dosar
};

const LivrareFacturaList = () => {
  const { getProcessVerbal } = useProcesVerbal();
  const [colete, setColete] = useState(getCollection("dbo.Colet"));
  const [facturi, setFacturi] = useState(
    getCollection("dbo.Factura").sort(lipsaProcesVerbal)
  );
  const [procesVerbal, setProcesVerbal] = useState(getProcessVerbal(facturi));

  subscribe(
    ["DosarChanged", "FacturaChanged", "ColetChanged", "ScanningChanged"],
    () => {
      setColete(getCollection("dbo.Colet"));
      const facturi = getCollection("dbo.Factura").sort(lipsaProcesVerbal);
      setFacturi(facturi);
      setProcesVerbal(getProcessVerbal(facturi));
    }
  );

  // derived state

  const displayBy: "ScanatCamion" | "ScanatLivrat" = facturi.find(
    (f) => f.NumarColete > f.ScanatCamion // f.ScanatLivrat || f.ScanatRefuz
  )
    ? "ScanatCamion"
    : "ScanatLivrat";

  const count =
    displayBy === "ScanatCamion"
      ? {
          total: facturi.reduce((acc, factura) => acc + factura.NumarColete, 0),
          done: facturi.reduce((acc, factura) => acc + factura[displayBy], 0),
          refuz: {
            colete: colete.filter((c) => c.ScanatRefuz).length,
            persoane: 0,
          },
        }
      : {
          total: facturi.length,
          done: facturi.filter(
            (f) => f.ScanatLivrat + f.ScanatRefuz === f.NumarColete
          ).length,
          refuz: {
            colete: colete.filter((c) => c.ScanatRefuz).length,
            persoane: facturi.filter((f) => f.ScanatRefuz).length,
          },
        };

  return (
    <>
      {procesVerbal && <ProcesVerbal {...procesVerbal} />}
      <div className="row">
        <LivrareFacturaStatus
          title={
            displayBy === "ScanatCamion"
              ? "Colete încărcate "
              : "Facturi livrate "
          }
          totalCount={count.total}
          doneCount={count.done}
          refuzCount={count.refuz.colete}
        />
      </div>
      {facturi.map((factura) => (
        <LivrareFacturaItem
          {...{
            key: factura.Id,
            factura,
            setProcesVerbal,
            colete: colete.filter((c) => c.IdFactura === factura.Id),
          }}
        />
      ))}
    </>
  );
};

export default LivrareFacturaList;

export const LivrareFacturaStatus = ({
  title = "Colete încărcate " as "Colete încărcate " | "Facturi livrate ",
  doneCount = 0,
  totalCount = 0,
  refuzCount = 0,
}) => {
  return (
    <h5 className="justify-content-between">
      {title === "Facturi livrate " && !!refuzCount && (
        <span>
          <RefuzIcon />
          <b className="text-larger text-danger"> {refuzCount}</b>
        </span>
      )}
      <span>
        {title}
        <b className="text-larger">
          <Progress total={totalCount} done={doneCount} />
        </b>
      </span>
    </h5>
  );
};
