import { useState } from "react";
import Scanner from "../../components/Scanner";
import DepozitPaletConsumer from "../../components/DepozitPaletConsumer";
import { publish, subscribe } from "../../common/events";
import { PaletIcon } from "../../components/StatusIcons";
import { useModal } from "../../hooks/useModal";
import ScannerInput from "../../components/ScannerInput";

const DepozitPaletPage = () => {
  const [barcode, setBarcode] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const { setModal } = useModal();

  const handleSearch = (value: string) => setBarcode(value);

  subscribe("CameraScanningChanged", ({ detail }) => setIsScanning(detail));
  subscribe("PaletScanComplet", () => [
    setIsScanning(false),
    publish("CameraScanningChanged", false),
    setModal(
      <ins>Toate coletele din dosar au fost marcate "palet" sau "liber".</ins>,
      "Paletare dosar completă"
    ),
  ]);

  return (
    <>
      {isScanning ? (
        <Scanner {...{ handleSearch, isScanning, setIsScanning }}>
          <DepozitPaletConsumer barcode={barcode} />
        </Scanner>
      ) : (
        <div>
          <h5>
            <PaletIcon /> &nbsp; Paletare colete
          </h5>
          <ScannerInput {...{ handleSearch }} />
          <DepozitPaletConsumer {...{ barcode }} />
        </div>
      )}
    </>
  );
};

export default DepozitPaletPage;
