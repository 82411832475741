import { useEffect, useState } from "react";
import Scanner from "../../components/Scanner";
import LivrareFacturaList from "../../components/LivrareFacturaList";
import ScannerInput from "../../components/ScannerInput";
import { publish, subscribe } from "../../common/events";
import { useModal } from "../../hooks/useModal";
import CamionRefuzConsumer from "../../components/CamionRefuzConsumer";
import { useNavigate, useParams } from "react-router-dom";
import { getCollection } from "../../common/localEntity";
import { useScanner } from "../../hooks/useScanner";

const CamionRefuzPage = () => {
  const { idFactura = 0 } = useParams();
  const { embeddedScanner } = useScanner();
  const [isIdValid] = useState(
    !!getCollection("dbo.Factura").find((f) => f.Id === +idFactura)
  );
  const [barcode, setBarcode] = useState("");
  const [isScanning, setIsScanning] = useState(isIdValid && !embeddedScanner);
  const [PVSnapshot, setPVSnapshot] = useState(false);
  const { setModal } = useModal();
  const navigate = useNavigate();

  const handleSearch = (value: string) => setBarcode(value);
  subscribe("CameraScanningChanged", (e) => [
    setBarcode(""),
    setIsScanning(e.detail),
  ]);
  
  subscribe("ProcesVerbalSnapshot", (e) => [
    setBarcode(""),
    setPVSnapshot(e.detail),
  ]);

  subscribe("FacturaScanComplet", (e) => [
    publish("CameraScanningChanged", false),
    setModal(e.detail, "Scanare completă"),
    navigate("/camion-livrat"),
  ]);

  useEffect(() => {
    if (!isIdValid) {
      setModal(
        <span className="text-danger">
          Factura cu id-ul {idFactura} nu a fost găsită!
        </span>,
        "Eroare"
      );
      navigate("/");
    }
  }, [isIdValid, idFactura, navigate, setModal]);

  return (
    <>
      {isScanning ? (
        <Scanner
          {...{
            handleSearch: setBarcode,
            isScanning,
          }}
        >
          <CamionRefuzConsumer barcode={barcode} />
        </Scanner>
      ) : (
        PVSnapshot || (
          <div className="row">
            <CamionRefuzConsumer barcode={barcode} />
            <ScannerInput {...{ handleSearch }} />
          </div>
        )
      )}
      <LivrareFacturaList />
    </>
  );
};

export default CamionRefuzPage;
