import { ColetEntity, FacturaEntity } from "../../../backend/src/shared/entity";
import { Link } from "react-router-dom";
import { StatusLivrare } from "./StatusLivrare";
import Phone from "./Phone";
import { StatusLivrareIcon } from "./StatusLivrareIcon";
import { useState } from "react";
import Ramburs from "./Ramburs";

const LivrareFacturaItem = ({
  factura,
  colete,
  setProcesVerbal,
}: {
  factura: FacturaEntity;
  colete: ColetEntity[];
  setProcesVerbal: (factura: FacturaEntity) => void;
}) => {
  let { Cumparator, Telefon, Document, NumarColete } = factura;

  const [details, setDetails] = useState(false);

  return (
    <div className="row">
      <article>
        <div className="justify-content-between align-items-start">
          <div className="-col-5 me-0 pe-0">
            <span className="text-center">
              <p className="text-muted m-0">
                <b className="text-larger">{NumarColete}</b>
              </p>
              <p className="text-muted mb-2">
                <small>Colete</small>
              </p>
            </span>
            <Ramburs {...factura} />
          </div>
          <div className="-col-7 ms-0 ps-0 text-end">
            <span className="text-muted text-large">{Cumparator}</span> <br />
            <label className="mb-2 text-large">
              <Phone>{Telefon}</Phone>
            </label>
            <StatusLivrare {...factura} />
            {/* <span className="text-muted">Valoare </span>
            <strong> {Valoare.toFixed(2)} lei</strong> */}
          </div>
        </div>
        <hr />
        <div className="justify-content-between">
          <ProcesVerbalStatus {...{ factura, setProcesVerbal }} />
          <Link
            to=""
            onClick={(e) => [e.preventDefault(), setDetails(!details)]}
          >
            Factura {Document}
            <i className="ms-2 fa-fw fa-solid fa-arrow-down-short-wide"></i>
          </Link>
        </div>
        <div>
          {details &&
            colete?.map((colet) => (
              <p key={colet.Id} className="mb-0 mt-2 text-start">
                <StatusLivrareIcon {...colet} /> {colet.Descriere}
              </p>
            ))}
        </div>
      </article>
    </div>
  );
};

export default LivrareFacturaItem;

const ProcesVerbalStatus = ({
  factura,
  setProcesVerbal,
}: {
  factura: FacturaEntity;
  setProcesVerbal: (factura: FacturaEntity) => void;
}) => {
  const { NumarColete, ScanatLivrat, ScanatRefuz, ProcesVerbal } = factura;
  if (!(ScanatLivrat > 0 && ScanatLivrat + ScanatRefuz === NumarColete))
    return <div></div>;
  return (
    <span>
      <Link
        className={ProcesVerbal ? "text-muted" : "text-warning"}
        to="/"
        onClick={(e) => [e.preventDefault(), setProcesVerbal(factura)]}
      >
        {ProcesVerbal ? "P.V. încărcat" : "P.V. lipsă"}
        <i
          className={`ms-2 fa-lg fa-solid ${
            ProcesVerbal
              ? "fa-check-double text-success"
              : "fa-xl fa-circle-camera"
          }`}
        ></i>
      </Link>
    </span>
  );
};
