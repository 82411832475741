import { toast } from "sonner";
import { getCollection } from "../common/localEntity";
import { useNavigate } from "react-router-dom";

const message = "Nici un dosar încărcat!";
const description = "Scanează codul QR al dosarului!";
export function useActiveRoute() {
  const navigate = useNavigate();
  const setActiveRoute = (scanning = "") => {
    const dosar = getCollection("dbo.Dosar")[0];
    if (!dosar?.Id) {
      return toast.error(message, { description });
      // return <>{`<h5>${message}</h5><h6>${description}</h6>`}</>;
    }
    // verific statusul dosarului, bazat pe coletele scanate
    const colete = getCollection("dbo.Colet");
    const coleteCount = colete.length;
    const camionCount = colete.filter((c) => c.ScanatCamion).length;
    if (camionCount === coleteCount) {
      // încarcarea pe camion este completă, se poate face livrarea
      navigate(`/camion-livrat/${scanning}`);
    } else if (dosar.Paletare) {
      // trebuie facută mutarea pe camion de pe tir
      navigate(`/camion-palet/%20/${scanning}`);
    } else {
      // trebuie finalizată încărcarea pe camion
      navigate(`/camion-colet/${scanning}`);
    }
    // return <></>;
  };

  return { setActiveRoute };
}
