import { useState } from "react";
import Input from "../../components/Input";
import DepozitPaletConsumer from "../../components/DepozitPaletConsumer";

const ScannerSamplePage = () => {
  const [barcode, setBarcode] = useState("");
  return (
    <>
      <form>
        <Input
          type="text"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
        />
      </form>
      <div className="text-end">
        <DepozitPaletConsumer barcode={barcode} />
      </div>
    </>
  );
};

export default ScannerSamplePage;
