import { useState } from "react";
import { Link } from "react-router-dom";
import { publish } from "../common/events";
import Fa from "./FontAwesome";
import { useScanner } from "../hooks/useScanner";

const ScannerInput = ({
  placeholder = "Scanează palet / colet ...",
  handleSearch = (value: string): void => void 0,
}) => {
  const [value, setValue] = useState("");
  const { embeddedScanner } = useScanner();
  const deactivate = () =>
    embeddedScanner && document.getElementById("cameraScanningId")?.focus();
  return (
    <fieldset className="input-scanner">
      <input
        className="m-0"
        type="text"
        inputMode="none"
        id="scannerInput"
        name="scannerInput"
        value={value}
        placeholder={placeholder}
        onKeyUp={(e) =>
          e.key === "Enter" && [handleSearch(value), setValue(""), deactivate()]
        }
        onChange={(e) => [e.preventDefault(), setValue(e.target.value)]}
      />
      <Link
        to="/"
        id="cameraScanningId"
        onClick={(e) => [
          e.preventDefault(),
          publish("CameraScanningChanged", true),
        ]}
      >
        <Fa light="camera-viewfinder fa-2xl fa-fw" />
      </Link>
    </fieldset>
  );
};

export default ScannerInput;
