import { useEffect, useState } from "react";
import ErrorBox from "./ErrorBox";
import { toast } from "sonner";
import { getCollection, setCollection, setQueue } from "../common/localEntity";
import { publish } from "../common/events";
import { LivrareFacturaStatus } from "./LivrareFacturaList";

const CamionColetConsumer = ({ barcode = "" }) => {
  const [error, setError] = useState("");
  const [dosar] = useState(getCollection("dbo.Dosar")[0]);
  const [facturi] = useState(getCollection("dbo.Factura"));
  const [colete, setColete] = useState(getCollection("dbo.Colet"));
  const [oldcode, setOldCode] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(true);

  // derived state
  const totalCount = colete?.length || 0;
  const doneCount = colete?.filter((c) => c.ScanatCamion).length || 0;

  // effects
  useEffect(() => publish("ColetChanged"), [colete]);

  if (!(dosar && colete.length)) {
    return <h5>Dosarul nu a fost încărcat, scanează codul QR al dosarului</h5>;
  }
  if (!barcode && error) setError("");

  if (barcode && barcode !== oldcode) {
    setOldCode(barcode);
    setError("");
    const colet = colete.find((c) => c.IdSursa === +barcode);
    if (colet && colet.ScanatCamion) {
      let message = `Coletul ${colet.Id} din dosarul ${dosar.Nume} a fost scanat deja!`;
      setError(message);
      toast(message);
    } else if (colet) {
      setQueue("dbo.Colet", { ...colet, ScanatCamion: new Date() });
      const colete = getCollection("dbo.Colet");
      setColete(colete);
      facturi
        .filter((f) => f.Id === colet.IdFactura)
        .forEach(
          (f) =>
            (f.ScanatCamion = colete.filter(
              (c) => c.ScanatCamion && c.IdFactura === f.Id
            ).length)
        );
      setCollection("dbo.Factura", facturi); // update facturi din localEntity
    } else {
      let message = `Coletul ${barcode} nu face parte din dosarul ${dosar.Nume}!`;
      setError(message);
      toast.error(message);
    }
  }

  if (totalCount === doneCount && isOpenModal)
    return (
      <dialog open={isOpenModal}>
        <article>
          <header>
            <a
              href="/"
              aria-label="Close"
              className="close"
              onClick={(e) => [e.preventDefault(), setIsOpenModal(false)]}
            >
              {""}
            </a>
            <p className="text-large">Scanare finalizată</p>
          </header>
          <div className="row">
            <LivrareFacturaStatus
              {...{
                title: "Colete încărcate ",
                totalCount,
                doneCount,
              }}
            />
          </div>
          <p className="float-end">
            <a
              href="/camion-livrat"
              role="button"
              onClick={(e) => [setIsOpenModal(false)]}
            >
              Închide
            </a>
          </p>
        </article>
      </dialog>
    );
  return <ErrorBox>{error}</ErrorBox>;
};

export default CamionColetConsumer;
