import { useState } from "react";
import { useParams } from "react-router-dom";
import Scanner from "../../components/Scanner";
import CamionColetConsumer from "../../components/CamionColetConsumer";
import LivrareFacturaList from "../../components/LivrareFacturaList";
import ScannerInput from "../../components/ScannerInput";
import { subscribe } from "../../common/events";
import { useScanner } from "../../hooks/useScanner";

const CamionColetPage = () => {
  const { scanning = false } = useParams();
  const [barcode, setBarcode] = useState("");
  const { embeddedScanner } = useScanner();
  const [isScanning, setIsScanning] = useState(!!scanning && !embeddedScanner);

  const handleSearch = (value: string) => setBarcode(value);

  subscribe("CameraScanningChanged", ({ detail }) => setIsScanning(detail));

  subscribe("CameraScanningChanged", (e) => [
    setBarcode(""),
    setIsScanning(e.detail),
  ]);

  return (
    <>
      {isScanning ? (
        <Scanner
          {...{
            handleSearch: setBarcode,
            isScanning,
          }}
        >
          <CamionColetConsumer barcode={barcode} />
        </Scanner>
      ) : (
        <div className="row">
          <h5>Încărcare colete pe camion</h5>
          <ScannerInput {...{ handleSearch }} />
          <CamionColetConsumer barcode={barcode} />
        </div>
      )}

      <LivrareFacturaList />
    </>
  );
};

export default CamionColetPage;
