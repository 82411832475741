import { useEffect, useState } from "react";
import ErrorBox from "./ErrorBox";
import { Progress } from "./StatusLivrare";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import {
  entityReducer,
  getCollection,
  setCollection,
  setQueue,
} from "../common/localEntity";
import { useUser } from "../hooks/useUser";
import { ColetEntity, DosarEntity } from "../../../backend/src/shared/entity";
import { publish } from "../common/events";
import { StatusLivrareIcon } from "./StatusLivrareIcon";
import { PaletIcon } from "./StatusIcons";

const CamionPaletConsumer = ({ barcode = "" }) => {
  const [error, setError] = useState("");
  const [dosar] = useState(getCollection("dbo.Dosar")[0]);
  const [colete, setColete] = useState(getCollection("dbo.Colet"));
  const [oldcode, setOldCode] = useState("");

  // derived state
  const liberCount = colete?.filter((c) => c.ScanatLiber).length || 0;
  const liberCamion =
    colete?.filter((c) => c.ScanatCamion && c.ScanatLiber).length || 0;
  const paletCount =
    colete?.filter(
      (val, idx, self) =>
        val.IndexPalet &&
        idx === self.findIndex((e) => e.IndexPalet === val.IndexPalet)
    ).length || 0;
  const paletCamion =
    colete?.filter(
      (val, idx, self) =>
        val.IndexPalet &&
        val.ScanatCamion &&
        idx === self.findIndex((e) => e.IndexPalet === val.IndexPalet)
    ).length || 0;

  useEffect(() => {
    if (
      liberCamion + paletCamion &&
      liberCamion + paletCamion === liberCount + paletCount
    ) {
      setCollection(
        "dbo.Factura",
        entityReducer(
          getCollection("dbo.Factura"),
          getCollection("dbo.Colet"),
          ["Id", "IdFactura"],
          ["ScanatCamion"]
        )
      );
      publish(
        "CamionPaletComplet",
        <div className="justify-content-start">
          <i className="me-2 fa-xl fa-solid fa-circle-check text-success"></i>
          <span className="ms-2">
            Toată marfa "<b className="text-success">{dosar.Nume}</b>" pentru "
            <b className="text-success">{dosar.Auto}</b>" a fost scanată cu
            succes! <br />
          </span>
        </div>
      );
    }
  }, [dosar, liberCamion, paletCamion, liberCount, paletCount]);
  if (!barcode && error) setError("");

  if (!(dosar && colete.length)) {
    return (
      <h5>Dosarul nu a fost încărcat, scanează codul de bare al dosarului</h5>
    );
  }

  if (barcode && barcode !== oldcode) {
    setOldCode(barcode);
    setError("");
    const colet = colete.find((c) => c.IdSursa === +barcode && c.ScanatLiber);
    if (!dosar.Paletare) {
      setError(
        `Dosarul "${dosar.Nume}" nu este marcat că necesită "Paletare"!`
      );
      toast.error(
        `Dosarul "${dosar.Nume}" nu este marcat că necesită "Paletare"!`
      );
    } else if (colet) {
      setQueue("dbo.Colet", { ...colet, ScanatCamion: new Date() });
      setColete(getCollection("dbo.Colet"));
    } else {
      // este scanare palet, verific dacă este un palet valid
      try {
        const palet = JSON.parse(
          atob(barcode.includes("/@") ? barcode.split("/@")[1] : barcode)
        );
        console.log("palet", palet);
        if (
          colete.find(
            (colet) =>
              colet.IndexPalet === palet.IndexPalet &&
              colet.IdDosar === palet.IdDosar
          )
        ) {
          colete.forEach((colet) => {
            if (colet.IndexPalet === palet.IndexPalet) {
              setQueue("dbo.Colet", { ...colet, ScanatCamion: new Date() });
            }
          });
          setColete(getCollection("dbo.Colet"));
        } else {
          throw new Error(
            `Codul scanat nu este "colet" sau "palet" din ${dosar.Nume} !`
          );
        }
      } catch (e) {
        console.error(e);
        setError(`Codul scanat nu aparține de dosarul "${dosar.Nume}" !`);
        toast.error(`Codul scanat nu aparține de dosarul "${dosar.Nume}" !`);
      }
    }
  }

  if (!(dosar && colete?.length)) return <h5>Scanează un colet</h5>;

  return (
    <CamionPaletStatus
      {...{
        dosar,
        error,
        paletCount,
        paletCamion,
        liberCamion,
        liberCount,
        colete,
      }}
    />
  );
};

export default CamionPaletConsumer;

const CamionPaletStatus = ({
  dosar = {} as DosarEntity,
  error = "",
  paletCount = 0,
  paletCamion = 0,
  liberCamion = 0,
  liberCount = 0,
  colete = [] as ColetEntity[],
}) => {
  const { user } = useUser();
  const [details, setDetails] = useState("" as "colete" | "paleti" | "");
  const onDetails = (value: "colete" | "paleti") =>
    setDetails((prev) => (prev === value ? "" : value));

  const paleti = Array.from(
    new Set(colete.filter((c) => c.IndexPalet).map((c) => c.IndexPalet))
  ).map((c) => ({
    IndexPalet: c,
    NumarColete: colete.filter((p) => p.IndexPalet === c).length,
  }));

  return (
    <>
      <h5>
        {error && <ErrorBox>{error}</ErrorBox>}
        <div className="text-end">
          Dosar
          <Link
            className="ms-2"
            to={user.isSofer ? `` : `/dosar-details/${dosar?.Id}`}
          >
            {dosar?.Nume}
          </Link>
        </div>
        <hr />
        <span className="float-end">
          <Link
            to=""
            onClick={(e) => [e.preventDefault(), onDetails("paleti")]}
          >
            <Progress total={paletCount} done={paletCamion} /> paleți
          </Link>
        </span>
        {!!liberCount && (
          <Link
            to=""
            onClick={(e) => [e.preventDefault(), onDetails("colete")]}
          >
            <Progress total={liberCount} done={liberCamion} /> colete libere
          </Link>
        )}
      </h5>
      <div>
        {details === "colete" &&
          colete
            ?.filter((c) => c.ScanatLiber)
            .map((colet) => (
              <p key={colet.Id} className="mb-0 mt-2 text-start">
                <StatusLivrareIcon {...colet} /> {colet.Descriere}
              </p>
            ))}
        {details === "paleti" &&
          paleti?.map((p) => (
            <p key={p.IndexPalet} className="mb-0 mt-2 text-start">
              <PaletIcon /> Palet {p.IndexPalet}/{paleti.length}
               - {p.NumarColete} colete
            </p>
          ))}
      </div>
    </>
  );
};
