import { Entity, EntityName, EntityType } from "../../../backend/src/shared/entity";
import sql from "./sql";

let runningSyncTask = 0;

setInterval(() => { // sincronizare date locale cu sql server
    if (navigator.onLine && 0 === runningSyncTask) {
        const queue = getQueue();
        const collections = Object.keys(queue) as Array<keyof typeof queue>;
        collections.forEach((collection) => {
            // console.log(`SyncTask: ${collection} ${queue[collection].length} items`);
            queue[collection].forEach((entity) => {
                runningSyncTask++;
                sql.saveEntity(collection, entity)
                    .then(() => removeQueue(collection, entity))
                    .catch(console.error)
                    .finally(() => runningSyncTask--);
            });
        });
    }
}, 3000);

export function getCollection<T extends EntityName>(collection: T): EntityType<T>[] {
    return JSON.parse(localStorage.getItem(collection) || '[]');
}

export function setCollection<T extends EntityName>(collection: T, entities: EntityType<T>[]) {
    return localStorage.setItem(collection, JSON.stringify(entities));
}

export function getEntity<T extends EntityName>(collection: T, id: number) {
    return getCollection(collection).find((item) => item.Id === id);
}

export function setEntity<T extends EntityName>(collection: T, entity: EntityType<T>) {
    if (entity?.Id) {
        const entities = getCollection(collection);
        const savedEntity = entities.find((item) => item.Id === entity.Id);
        savedEntity ?
            Object.assign(savedEntity, entity) :
            entities.push(entity);
        return localStorage.setItem(collection, JSON.stringify(entities));
    }
}

export function getQueue<T extends EntityName>(): { [key in T]: EntityType<T>[] } {
    return JSON.parse(localStorage.getItem('__queue__') || '{}');
}

export function setQueue<T extends EntityName>(collection: T, entity: EntityType<T>) {
    if (entity?.Id) {
        setEntity(collection, entity);
        const queue = getQueue();
        queue[collection] = queue[collection] || [];
        queue[collection].push(entity);
        localStorage.setItem('__queue__', JSON.stringify(queue));
    }
    return entity;
}

export function removeQueue<T extends EntityName>(collection: T, entity: EntityType<T>) {
    if (entity?.Id) {
        setEntity(collection, entity);
        const queue = getQueue();
        queue[collection] = queue[collection] || [];
        // remove the first element from the queue that has the same Id as the entity if exists
        const index = queue[collection].findIndex((item) => item.Id === entity.Id);
        if (index >= 0) {
            queue[collection].splice(index, 1);
        }
        return localStorage.setItem('__queue__', JSON.stringify(queue));
    }
}

export function clearQueue() {
    return localStorage.setItem('__queue__', '{}');
}

export function entityReducer<T extends Entity, S extends Entity>(
    target: T[], // target collection
    source: S[], // source collection
    relation: [keyof T, keyof S], // relation field
    fields: Array<keyof T & keyof S>, // fields to reduce
) {
    const [pk, fk] = relation;//Array.from(relation.entries())[0];
    const mapped = new Map(
        target.map((t) => [t, source.filter((s) => +s[fk] === +t[pk])])
    );
    mapped.forEach((s, t) => {
        fields.forEach((p) => {
            t[p] = s.filter((s) => !!s[p]).length as any;
        });
    });
    return target;
};
