import { SyntheticEvent, useEffect, useState } from "react";
import { FacturaEntity } from "../../../backend/src/shared/entity";
import CameraSnapshot from "./CameraSnapshot";
import { setQueue } from "../common/localEntity";
import { publish } from "../common/events";
import { Link } from "react-router-dom";

const ProcesVerbal = (props: FacturaEntity) => {
  const [picture, setPicture] = useState(props.ProcesVerbal);

  //effects
  useEffect(() => {
    publish("ProcesVerbalSnapshot", true);
    return () => publish("ProcesVerbalSnapshot", false);
  }, []);

  // events
  const handleSave = (e: SyntheticEvent) => {
    e.preventDefault();
    publish(
      "FacturaChanged",
      setQueue("dbo.Factura", { ...props, ProcesVerbal: picture })
    );
  };

  return (
    <>
      <h5 className="text-center mb-0">
        <b className="text-larger"> Proces verbal </b> <br />
        {props.Document} - {props.Cumparator}
      </h5>
      {picture ? (
        <>
          <article className="m-0 text-x-large">
            <Link
              to=""
              className="d-inline w-auto"
              onClick={(e) => [e.preventDefault(), setPicture("")]}
            >
              <i className="fa-2xl fa-solid fa-circle-camera"></i>
            </Link>
            <Link
              to="/"
              className="d-inline w-auto float-end"
              onClick={handleSave}
            >
              <i className="fa-2xl fa-solid fa-circle-check text-success"></i>
            </Link>
          </article>
          <img src={picture} alt="Proces Verbal" className="w-100" />
        </>
      ) : (
        <CameraSnapshot active={true} handleResult={setPicture} />
      )}
    </>
  );
};

export default ProcesVerbal;
