import { useState } from "react";
import Scanner from "../../components/Scanner";
import { publish, subscribe } from "../../common/events";
import DepozitReturConsumer from "../../components/DepozitReturConsumer";
import { ReturIcon } from "../../components/StatusIcons";
import { useModal } from "../../hooks/useModal";
import ScannerInput from "../../components/ScannerInput";

const DepozitReturPage = () => {
  const [barcode, setBarcode] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const { setModal } = useModal();

  const handleSearch = (value: string) => setBarcode(value);

  subscribe("CameraScanningChanged", ({ detail }) => setIsScanning(detail));

  subscribe("ReturScanComplet", () => [
    setIsScanning(false),
    publish("CameraScanningChanged", false),
    setModal(
      `Toate coletele din dosar au fost marcate "retur".`,
      "Scanare retur completă"
    ),
  ]);

  return (
    <>
      {isScanning ? (
        <Scanner
          {...{ handleSearch, isScanning, setIsScanning }}
        >
          <DepozitReturConsumer {...{ barcode }} />
        </Scanner>
      ) : (
        <div>
          <h5>
            <ReturIcon /> &nbsp; Retur colete
          </h5>
          <ScannerInput {...{ handleSearch }} />
          <DepozitReturConsumer {...{ barcode }} />
        </div>
      )}
    </>
  );
};

export default DepozitReturPage;
