import { useState } from "react";
import { useParams } from "react-router-dom";
import Scanner from "../../components/Scanner";
import CamionPaletConsumer from "../../components/CamionPaletConsumer";
import { publish, subscribe } from "../../common/events";
import ScannerInput from "../../components/ScannerInput";
import { useModal } from "../../hooks/useModal";
import { useUser } from "../../hooks/useUser";
import { useActiveRoute } from "../../hooks/useActiveRoute";

const CamionPaletPage = () => {
  const { paletQR, scanning = false } = useParams();
  const { setModal } = useModal();
  const { user } = useUser();
  const { setActiveRoute } = useActiveRoute();
  const [barcode, setBarcode] = useState(
    ((paletQR && paletQR.replace("@", "")) || "").trim()
  );
  const [isScanning, setIsScanning] = useState(!!scanning);

  subscribe("CameraScanningChanged", (e) => [
    setBarcode(""),
    setIsScanning(e.detail),
  ]);
  subscribe("CamionPaletComplet", (e) => [
    publish("CameraScanningChanged", false),
    setModal(e.detail, "Scanare completă"),
    user.isSofer && setActiveRoute(),
    setBarcode(""),
  ]);

  const handleSearch = (value: string) => {
    setBarcode(() => {
      if (value.includes("/@")) {
        return value.split("/@")[1]; // la scan palet am /@ înainte de QRcode
      }
      // dacă tastează operatorul, nu se resetează valoarea
      return value;
    });
  };

  return (
    <>
      {isScanning ? (
        <Scanner {...{ handleSearch, isScanning }}>
          <CamionPaletConsumer {...{ barcode }} />
        </Scanner>
      ) : (
        <div>
          <h5>Transfer paleți/colete libere pe camion</h5>
          <ScannerInput {...{ handleSearch }} />
          <CamionPaletConsumer {...{ barcode }} />
        </div>
      )}
    </>
  );
};

export default CamionPaletPage;
