import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/user/LoginPage";
import RegisterPage from "./pages/user/RegisterPage";
import Navbar from "./components/Navbar";
import LayoutSamplePage from "./pages/sample/LayoutSamplePage";
import { createContext, useState } from "react";
import PasswordRecoverPage from "./pages/user/PasswordRecoverPage";
import PasswordResetPage from "./pages/user/PasswordResetPage";
import DosarDetailsPage from "./pages/dosar/DosarDetailsPage";
import ConfigPage from "./pages/user/ConfigPage";
import ValidateEmailPage from "./pages/user/ValidateEmailPage";
import DosarIndexPage from "./pages/dosar/DosarIndexPage";
import BarcodeFacturiPage from "./pages/sample/BarcodeFacturiPage";
import DosarScanPage from "./pages/camion/DosarScanPage";
import ScannerSamplePage from "./pages/sample/ScannerSamplePage";
import BarcodeColetePage from "./pages/sample/BarcodeColetePage";
import Modal from "./components/Modal";
import CamionRefuzOptionPage from "./pages/camion/CamionRefuzOptionPage";
import ColeteStatusReset from "./pages/colet/ColeteStatusReset";
import { Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DosarEditPage from "./pages/dosar/DosarEditPage";
import DepozitPaletPage from "./pages/depozit/DepozitPaletPage";
import DepozitReturPage from "./pages/depozit/DepozitReturPage";
import CamionPaletPage from "./pages/camion/CamionPaletPage";
import BarcodePaletiPage from "./pages/sample/BarcodePaletiPage";
import CamionColetPage from "./pages/camion/CamionColetPage";
import CamionLivratPage from "./pages/camion/CamionLivratPage";
import CamionRefuzPage from "./pages/camion/CamionRefuzPage";
import CamionDecontPage from "./pages/camion/CamionDecontPage";
import SumarArticoleDosare from "./pages/raport/SumarArticoleDosare";

const queryClient = new QueryClient();

export const TitleContext = createContext<{
  title: JSX.Element;
  setTitle: (title: JSX.Element) => void;
}>(null as any);

const App = () => {
  const [title, setTitle] = useState(<span>Laguna delivery</span>);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <TitleContext.Provider value={{ title, setTitle }}>
          <Router>
            <audio src="/audio/scanner-beep.mp3"></audio>
            <Navbar />
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route
                path="/login/:redirect?/:status?"
                element={<LoginPage />}
              />
              <Route path="/register" element={<RegisterPage />} />
              <Route
                path="/password-recover"
                element={<PasswordRecoverPage />}
              />
              <Route
                path="/password-reset/:token"
                element={<PasswordResetPage />}
              />
              <Route
                path="/validate-email/:token"
                element={<ValidateEmailPage />}
              />
              <Route path="/config" element={<ConfigPage />} />
              <Route
                path="/colete-status-reset/:idFactura"
                element={<ColeteStatusReset />}
              />
              <Route path="/depozit-palet" element={<DepozitPaletPage />} />
              <Route path="/depozit-retur" element={<DepozitReturPage />} />
              <Route
                path="/camion-refuz-option"
                element={<CamionRefuzOptionPage />}
              />
              <Route
                path="/camion-refuz/:idFactura/:motivRefuz?"
                element={<CamionRefuzPage />}
              />
              <Route
                path="/camion-livrat/:scanning?"
                element={<CamionLivratPage />}
              />
              <Route
                path="/camion-colet/:scanning?"
                element={<CamionColetPage />}
              />
              <Route
                path="/camion-palet/:paletQR?/:scanning?"
                element={<CamionPaletPage />}
              />
              <Route path="/camion-decont" element={<CamionDecontPage />} />

              <Route path="/dosar-index" element={<DosarIndexPage />} />
              <Route path="/dosar-edit/:idDosar?" element={<DosarEditPage />} />
              <Route
                path="/dosar-details/:idDosar"
                element={<DosarDetailsPage />}
              />
              <Route
                path="/dosar-scan/:idDosar/:paletQR?"
                element={<DosarScanPage />}
              />
              <Route path="/barcode-facturi" element={<BarcodeFacturiPage />} />
              <Route
                path="/barcode-paleti/:idDosar"
                element={<BarcodePaletiPage />}
              />
              <Route
                path="/barcode-colete/:idDosar"
                element={<BarcodeColetePage />}
              />
              <Route path="/layout-sample" element={<LayoutSamplePage />} />
              <Route path="/scanner-sample" element={<ScannerSamplePage />} />
              <Route
                path="/raport/sumar-articole-dosar"
                element={<SumarArticoleDosare />}
              />
            </Routes>
          </Router>
          <Toaster
            closeButton
            richColors
            position="bottom-right"
            theme="system"
            expand
          />
          <Modal />
        </TitleContext.Provider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
