import { ChangeEvent, SyntheticEvent, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DosarEntity } from "../../../../backend/src/shared/entity";
import sql, { SqlError } from "../../common/sql";
import { toast } from "sonner";
import Input from "../../components/Input";
import { useQuery } from "@tanstack/react-query";
import ErrorBox from "../../components/ErrorBox";

const newEntity: DosarEntity = {
  Nume: "",
  Descriere: "",
  Paletare: true,
  Sofer: "",
  Auto: "",
  NumarPaleti: 3,
} as DosarEntity;

const DosarEditPage = () => {
  const { idDosar } = useParams();
  const [model, setModel] = useState<DosarEntity>(newEntity);
  const [error, setError] = useState<SqlError>();
  const frm = useRef<HTMLFormElement>(null);

  const { isLoading, isFetching } = useQuery<Array<DosarEntity>>({
    queryKey: ["dosar", idDosar],
    queryFn: () =>
      idDosar ? sql.dosarByIdQuery({ idDosar }) : Promise.resolve([newEntity]),
    onSuccess: ([dosar]) => setModel(dosar),
    onError: (error) => setError(error as SqlError),
  });

  const navigate = useNavigate();

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const validity = frm.current?.checkValidity();
    if (!validity) {
      toast.error("Completează toate câmpurile obligatorii !");
      // set the focus on the first invalid input
      const invalid = frm.current?.querySelector(
        ":invalid"
      ) as HTMLInputElement;
      return invalid?.focus();
    }
    sql
      .saveEntity("dbo.Dosar", model as DosarEntity)
      .then((dosar) => {
        navigate(`/dosar-details/${dosar.Id}`);
      })
      .catch((e) => [console.error(e), setError(e)]);
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setModel({ ...model, [e.target.name]: e.target.value });
    setError(undefined);
  };

  if (isLoading || isFetching) return <progress></progress>;
  if (error)
    return (
      <form>
        <ErrorBox>{error}</ErrorBox>
      </form>
    );

  return (
    <form ref={frm}>
      <Input
        type="text"
        name="Nume"
        required
        value={model.Nume}
        onChange={handleChange}
      >
        Nume dosar transport ( ruta )
      </Input>
      <Input
        type="date"
        name="DataLivrare"
        required
        value={model.DataLivrare}
        onChange={handleChange}
      >
        Data transport
      </Input>
      <Input
        type="text"
        name="Descriere"
        value={model.Descriere}
        onChange={handleChange}
      >
        Alte Detalii
      </Input>

      <Input
        type="checkbox"
        name="Paletare"
        role="switch"
        checked={model.Paletare}
        onChange={(e) => setModel({ ...model, Paletare: e.target.checked })}
      >
        Ambalare colete pe paleți
      </Input>

      {model.Paletare && (
        <p className="mt-3">
          <label htmlFor="numarPaleti">Numărul de paleți</label>
          <select
            id="numarPaleti"
            name="NumarPaleti"
            value={model.NumarPaleti}
            onChange={handleChange}
          >
            <option value={0}>Adăugare la pregătire paleți</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
          <Input
            type="text"
            name="Auto"
            value={model.Auto}
            onChange={handleChange}
            required={model.Paletare}
          >
            Auto
          </Input>
          <Input
            type="text"
            name="Sofer"
            value={model.Sofer}
            onChange={handleChange}
          >
            Sofer
          </Input>
        </p>
      )}
      <footer>
        <Link
          to="/"
          role="button"
          className="me-2 mb-2"
          onClick={handleSubmit}
        >
          <i className="fa-fw fa-duotone fa-save"></i> Salvează modificările
        </Link>
        <Link
          to={idDosar ? `/dosar-details/${idDosar}` : `/dosar-index`}
          role="button"
          className="secondary"
        >
          <i className="fa-fw fa-duotone fa-undo"></i> Anulează modificările
        </Link>
      </footer>
    </form>
  );
};

export default DosarEditPage;
