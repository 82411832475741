import { useEffect, useState } from "react";
import { DosarEntity } from "../../../../backend/src/shared/entity";
import sql from "../../common/sql";
import ErrorBox from "../../components/ErrorBox";
import NavbarTitle from "../../components/NavbarTitle";
import { Link } from "react-router-dom";
import {
  DepozitIcon,
  CamionIcon,
  LivratIcon,
  RefuzIcon,
  ReturIcon,
  PaletIcon,
} from "../../components/StatusIcons";
import { formatDate } from "../../common/utils";
import { Progress, StatusLivrare } from "../../components/StatusLivrare";
import { StatusLivrareIcon } from "../../components/StatusLivrareIcon";
import { useQuery } from "@tanstack/react-query";
import { useModal } from "../../hooks/useModal";
import Input from "../../components/Input";

const calendar = [
  "-- toate --",
  "ieri",
  "azi",
  "mâine",
  "săptămâna trecută",
  "săptămâna aceasta",
  "săptămâna viitoare",
  "luna trecută",
  "luna aceasta",
  "luna viitoare",
];
const putParams = (params: any) => {
  localStorage.setItem("dosar-index-params", JSON.stringify(params));
};
const getParams = () => {
  const params = {
    finalizat: false,
    fullText: "",
    calendar: calendar[0],
  };
  return JSON.parse(
    localStorage.getItem("dosar-index-params") || JSON.stringify(params)
  ) as typeof params;
};
const DosarIndexPage = () => {
  const { setModal } = useModal();
  const [params, setParams] = useState(getParams());
  const {
    data: model,
    isLoading,
    error,
  } = useQuery<Array<DosarEntity>>({
    queryKey: ["dosar-index", params],
    queryFn: ({ queryKey: [, params] }) =>
      sql.fulltextDosarQuery(params as any),
  });
  const modalQRCode = (dosar: DosarEntity) => {
    if (dosar.Id) {
      setModal(
        <img src={`/api/dosarQRCode/${dosar.Id}`} alt="QR Loading ..." />,
        `QR code "${dosar.Nume}"`
      );
    }
  };

  useEffect(() => putParams(params), [params]);

  const handleReset = (dosar: DosarEntity) => {
    if (window.confirm(`Resetezi informații livrare din "${dosar.Nume}"?`)) {
      sql
        .dosarResetQuery({ idDosar: dosar.Id })
        .then(() => setParams({ ...params }));
    }
  };

  const handleDelete = (dosar: DosarEntity) => {
    if (
      window.confirm(
        `Ștergi dosarul și informațiile de livrare din "${dosar.Nume}"?`
      )
    ) {
      sql
        .dosarDeleteQuery({ idDosar: dosar.Id })
        .then(() => setParams({ ...params }));
    }
  };

  const closeMenu = () => {
    document
      .querySelectorAll("details[open]")
      .forEach((details) => details.removeAttribute("open"));
  };

  return (
    <>
      <NavbarTitle icon="fa-folder-tree">Dosare de transport</NavbarTitle>
      <ErrorBox>{error}</ErrorBox>

      <table>
        <thead>
          <tr>
            <td colSpan={10}>
              <small>
                <DepozitIcon /> depozit &ensp;
                <PaletIcon /> palet &ensp;
                <CamionIcon /> camion &ensp;
                <LivratIcon /> livrat &ensp;
                <RefuzIcon /> refuz &ensp;
                <ReturIcon /> retur &ensp;
              </small>
              <nav className="float-end">
                <ul>
                  <li className="p-0">
                    <Link to="/dosar-edit/">
                      <i className="fa-fw fa-xl fa-light fa-folder-plus"></i>{" "}
                      Adaugă dosar transport
                    </Link>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="pe-0">
              <div className="justify-content-between">
                <Input
                  type="checkbox"
                  name="finalizate"
                  role="switch"
                  checked={params.finalizat}
                  onChange={(e) =>
                    setParams({ ...params, finalizat: e.target.checked })
                  }
                  onClick={(e) =>
                    setParams({ ...params, finalizat: !params.finalizat })
                  }
                >
                  {params.finalizat
                    ? "Ascunde finalizate"
                    : "Arată și finalizate"}
                </Input>
                <span>livrare</span>
              </div>
            </td>
            <td colSpan={2}>
              <select
                id="calendar"
                name="calendar"
                value={params.calendar}
                required
                className="m-0"
                onChange={(e) =>
                  setParams({ ...params, calendar: e.target.value })
                }
              >
                {calendar.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </td>
            <td colSpan={4}>
              <input
                type="search"
                name="fullText"
                value={params.fullText}
                onChange={(e) =>
                  setParams({ ...params, fullText: e.target.value })
                }
                className="m-0"
              ></input>
            </td>
          </tr>
          <tr>
            <th></th>
            <th>Dosar</th>
            <th>Livrare</th>
            <th className="text-center m-0 p-0">Colete</th>
            <th>Status livrare</th>
            <th className="text-center m-0 p-0">
              Proces
              <br />
              Verbal
            </th>
            <th className="text-end">Decont</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={8}>
                <progress></progress>
              </td>
            </tr>
          )}
          {model?.map((dosar) => (
            <tr
              key={dosar.Id}
              className={dosar.FinalizatDe ? "table-success" : ""}
            >
              <td className="text-center text-larger px-0">
                <StatusLivrareIcon {...dosar} />
              </td>
              <td>
                <Link to={`/dosar-details/${dosar.Id}`}>{dosar.Nume}</Link>
                {!!dosar.Paletare &&
                  dosar.ScanatPalet + dosar.ScanatLiber < dosar.NumarColete && (
                    <small>
                      {" "}
                      <PaletIcon />
                    </small>
                  )}
                <br />
                {/* <small>{dosar.Descriere}</small> <br /> */}
                <small className="text-muted">
                  <i>
                    {formatDate(dosar.CreatLa)} {dosar.CreatDe}
                  </i>
                </small>
              </td>
              <td>
                <p className="mb-0 text-bold" >
                  <i className="fa-light fa-calendar-days me-2"></i>
                  {formatDate(dosar.DataLivrare, "text")}
                </p>
                <small className="text-muted">
                  <i>
                    {dosar.Auto} ({dosar.Sofer})
                  </i>
                </small>
              </td>
              <td className="text-center text-larger m-0 p-0">
                {dosar.NumarColete}
              </td>
              <td>
                <StatusLivrare {...dosar} />
                {dosar.ScanatRefuz > 0 && (
                  <>
                    <br />
                    <ReturIcon /> {"retur "}
                    <Progress
                      done={dosar.ScanatRetur}
                      total={dosar.ScanatRefuz}
                    />
                  </>
                )}
              </td>
              <td className="text-center">
                <Progress done={dosar.ProcesVerbal} />
              </td>
              <td className="text-end">
                {dosar.Valoare - dosar.ValoareRefuz ? (
                  <small className="text-bold">
                    {(dosar.Valoare - dosar.ValoareRefuz).toFixed(2)} lei
                  </small>
                ) : (
                  ``
                )}
              </td>
              <td>
                <nav>
                  <ul>
                    <li className="py-1">
                      <details role="list" dir="rtl">
                        <summary aria-haspopup="listbox" role="link"></summary>
                        <ul className="text-large" role="listbox">
                          <li>
                            <Link to={`/barcode-paleti/${dosar.Id}`}>
                              QR Paleți
                              <i className="mx-2 fa-sm fa-fw fa-light fa-barcode-read text-primary"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to={`/barcode-colete/${dosar.Id}`}>
                              QR Colete
                              <i className="mx-2 fa-sm fa-fw fa-light fa-barcode-read text-primary"></i>
                            </Link>
                          </li>
                          <li>
                            <a
                              href="/"
                              onClick={(e) => [
                                e.preventDefault(),
                                modalQRCode(dosar),
                              ]}
                            >
                              QR Dosar
                              <i className="mx-2 fa-sm fa-fw fa-light fa-barcode-read text-primary"></i>
                            </a>
                          </li>
                          <li>
                            <Link
                              to="/"
                              onClick={(e) => [
                                e.preventDefault(),
                                closeMenu(),
                                handleReset(dosar),
                              ]}
                            >
                              Reset informații livrare
                              <i className="mx-2 fa-sm fa-fw fa-light fa-sync text-warning"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/"
                              onClick={(e) => [
                                e.preventDefault(),
                                handleDelete(dosar),
                              ]}
                            >
                              Șterge dosar
                              <i className="mx-2 fa-sm fa-fw fa-light fa-trash text-danger"></i>
                            </Link>
                          </li>
                        </ul>
                      </details>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DosarIndexPage;
