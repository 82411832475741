import { publish } from "../common/events";

const setModal = (
  content: JSX.Element | string | null = null,
  title: JSX.Element | string = "Informare",
  action?: JSX.Element
) => {
  publish("DialogMessageChanged", content && { content, title, action });
};

export function useModal() {
  return { setModal };
}
