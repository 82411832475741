import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import sql from "../../common/sql";
import { SqlError } from "../../common/sql";
import {
  clearQueue,
  getCollection,
  setCollection,
  setQueue,
} from "../../common/localEntity";
import LoginSofer from "../../components/LoginSofer";
import ErrorBox from "../../components/ErrorBox";
import LivrareFacturaList from "../../components/LivrareFacturaList";
import { useUser } from "../../hooks/useUser";
import { publish } from "../../common/events";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";
import { useActiveRoute } from "../../hooks/useActiveRoute";

const DosarScanPage = () => {
  const { idDosar = "", paletQR = "" } = useParams();
  const [error, setError] = useState<SqlError>();
  const [loading, setLoading] = useState(window.navigator.onLine);
  const [dosar, setDosar] = useState(getCollection("dbo.Dosar")[0]);
  const { user, setUser } = useUser();
  const {setActiveRoute} = useActiveRoute();
  const navigate = useNavigate();
  // effects
  useEffect(() => {
    // verific dacă este un dosar cu paletare și dacă este făcut transferul pe camion
    if (
      !loading &&
      user.email &&
      dosar &&
      dosar.Paletare &&
      dosar.ScanatCamion < dosar.NumarColete
    ) {
      // transferul pe camion nu este complet,
      // redirectez la scanare camion a paleților/coletelor libere
      navigate(`/camion-palet/${paletQR}`);
    } else if (!loading && user.email && dosar) {
      setActiveRoute();
    }
  }, [loading, user, dosar, navigate, paletQR, setActiveRoute]);

  useQuery({
    queryKey: ["DosarDetailsQuery", user?.email],
    queryFn: ({ queryKey: [, email] }) =>
      email
        ? sql.dosarDetailsQuery({ idDosar })
        : Promise.reject("Login is required"),
    onSuccess: ([[_dosar], facturi, colete, motiveRetur]) => {
      if (dosar?.Id && dosar.Id !== _dosar.Id) {
        const message = paletQR
          ? `Paletul scanat nu aparține de "${dosar.Nume}" !\nDoriți trecerea la dosarul "${_dosar.Nume}" ?`
          : `Dosarul în lucru este "${dosar.Nume}" !\nDoriți trecerea la dosarul "${_dosar.Nume}" ?`;
        if (!window.confirm(message)) return setLoading(false);
      }
      if (!_dosar) return window.alert("Dosarul nu există!");
      clearQueue();
      _dosar.Sofer = user.email.split("@")[0];
      _dosar.Auto = user.email.split("@")[1];
      setCollection("dbo.Dosar", [_dosar]);
      setCollection("dbo.Colet", colete);
      setCollection("dbo.Factura", facturi);
      setCollection("dbo.MotivRetur", motiveRetur);
      publish("DosarChanged", _dosar);
      publish("ColetChanged", colete);
      publish("FacturaChanged", facturi);
      toast.success("Dosarul a fost actualizat!");
      setError(undefined);
      setLoading(false);
      setDosar(setQueue("dbo.Dosar", _dosar));
    },
    onError: (err) => {
      console.error(err);
      setLoading(false);
      setError(undefined);
      if (!user.email) return; // dacă nu e autentificat nu afișez eroarea
      if (window.navigator.onLine) {
        toast.error("Eroare la încărcarea dosarului!", {
          description: JSON.stringify(err),
        });
        setError(err as SqlError);
      } else {
        toast.error("Nu există conexiune la internet!");
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });

  if (!user.email) return <LoginSofer handleLogin={setUser} />;
  if (error) return <ErrorBox>{error}</ErrorBox>;
  if (loading) return <progress></progress>;
  return <LivrareFacturaList />;
};

export default DosarScanPage;
