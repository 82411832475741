import { useEffect, useState } from "react";
import ErrorBox from "./ErrorBox";
import { toast } from "sonner";
import { getCollection, setCollection, setQueue } from "../common/localEntity";
import { publish } from "../common/events";
import { useModal } from "../hooks/useModal";
import { Progress } from "./StatusLivrare";
import Ramburs from "./Ramburs";
import { LivratIcon, RefuzIcon } from "./StatusIcons";
import { useParams } from "react-router-dom";

const CamionRefuzConsumer = ({ barcode = "" }) => {
  const { idFactura = 0, motivRefuz = "" } = useParams();
  const [error, setError] = useState("");
  const [dosar] = useState(getCollection("dbo.Dosar")[0]);
  const [factura] = useState(
    getCollection("dbo.Factura").find((f) => f.Id === +idFactura)
  );
  const [facturi] = useState(getCollection("dbo.Factura"));
  const [colete, setColete] = useState(getCollection("dbo.Colet"));
  const [oldcode, setOldCode] = useState("");
  const { setModal } = useModal();
  // derived state
  const livratCount =
    colete?.filter((c) => c.IdFactura === factura?.Id && c.ScanatLivrat)
      .length || 0;
  const refuzCount =
    colete?.filter((c) => c.IdFactura === factura?.Id && c.ScanatRefuz)
      .length || 0;
  const coletCount =
    colete?.filter((c) => c.IdFactura === factura?.Id).length || 0;

  // effects
  useEffect(() => publish("ColetChanged"), [colete]);
  useEffect(() => {
    if (coletCount && coletCount === livratCount + refuzCount) {
      publish(
        "FacturaScanComplet",
        <span className="text-success">
          Toate coletele din factura {factura?.Document} au fost scanate!
        </span>
      );
    }
  }, [livratCount, refuzCount, coletCount, factura?.Document]);

  if (!(dosar && colete.length)) {
    return <h5>Dosarul nu a fost încărcat, scanează codul QR al dosarului</h5>;
  }
  if (!barcode && error) setError("");
  if (barcode && barcode !== oldcode && factura) {
    setOldCode(barcode);
    setError("");
    const colet = colete.find((c) => c.IdSursa === +barcode);
    if (colet && colet.IdFactura !== factura.Id) {
      let message = `Coletul ${colet.Descriere} nu face parte din factura ${factura.Document}!`;
      setError(message);
      setModal(
        <span className="text-danger">{message}</span>,
        "Scanare eronată"
      );
    } else if (colet && colet.ScanatRefuz) {
      let message = `Coletul ${colet.Descriere} a fost marcat "refuz"!`;
      toast(message);
    } else if (colet && colet.ScanatLivrat) {
      if (
        window.confirm(
          `Coletul ${colet.Descriere} este marcat "livrat". Doriți să-l marcați "refuz"?`
        )
      ) {
        setQueue("dbo.Colet", {
          ...colet,
          ScanatLivrat: null,
          ScanatRefuz: new Date(),
          MotivRetur: motivRefuz,
        });
        const colete = getCollection("dbo.Colet");
        setColete(colete);
        facturi
          .filter((f) => f.Id === colet.IdFactura)
          .forEach((f) => {
            f.ScanatLivrat = colete.filter(
              (c) => c.ScanatLivrat && c.IdFactura === f.Id
            ).length;
            f.ScanatRefuz = colete.filter(
              (c) => c.ScanatRefuz && c.IdFactura === f.Id
            ).length;
          });
        setCollection("dbo.Factura", facturi); // update facturi din localEntity
      }
    } else if (colet && !colet.ScanatRefuz) {
      setQueue("dbo.Colet", { ...colet, ScanatRefuz: new Date(), MotivRetur: motivRefuz });
      const colete = getCollection("dbo.Colet");
      setColete(colete);
      facturi
        .filter((f) => f.Id === colet.IdFactura)
        .forEach(
          (f) =>
            (f.ScanatRefuz = colete.filter(
              (c) => c.ScanatRefuz && c.IdFactura === f.Id
            ).length)
        );
      setCollection("dbo.Factura", facturi); // update facturi din localEntity
    } else {
      let message = `Coletul ${barcode} nu face parte din dosarul ${dosar.Nume}!`;
      setError(message);
      toast.error(message);
    }
  }
  if (!factura)
    return (
      <h5>
        Factura "{idFactura}"" nu a fost încărcată, scanează codul QR al
        dosarului și apoi încearcă din nou.
      </h5>
    );
  return (
    <>
      <h5 className="text-end">
        <div className="justify-content-between">
          <Ramburs {...factura} />
          <span>
            <div className="justify-content-between">
              <span>
                <LivratIcon />
                <b className="text-larger ms-1">
                  <Progress done={livratCount} total={coletCount} />
                </b>
              </span>
              <span>
                <RefuzIcon /> Refuz
                <b className="text-larger ms-1">
                  <Progress done={refuzCount} total={coletCount} />
                </b>
              </span>
            </div>
            <div>
              <small className="text-muted">
                {factura?.Cumparator} - factura &nbsp;
              </small>
              {factura?.Document}
            </div>
          </span>
        </div>
      </h5>
      <ErrorBox>{error}</ErrorBox>
    </>
  );
};

export default CamionRefuzConsumer;
