import { SyntheticEvent, useEffect, useState } from "react";
import { SyncStatusEntity } from "../../../backend/src/shared/entity";
import sql from "../common/sql";
import ErrorBox from "../components/ErrorBox";
import { formatDate } from "../common/utils";
import NavbarTitle from "../components/NavbarTitle";
import env, { ThemeOption } from "../common/env";
import { SqlError } from "../common/sql";
import { Link } from "react-router-dom";
import EmbeddedScannerSwitch from "./EmbeddedScannerSwitch";

const ConfigUser = () => {
  const [error, setError] = useState<SqlError>();
  const [model, setModel] = useState<Array<SyncStatusEntity>>([]);
  const [theme, setTheme] = useState(env.THEME.getTheme);
  const currentTheme = env.THEME.currentTheme;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!model.length) {
      sql
        .syncStatusListQuery()
        .then(setModel)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [model, setModel, setLoading]);

  const syncDatabases = (checkOnly: number) =>
    sql
      .syncDatabasesQuery({ checkOnly })
      .then(setModel)
      .catch(setError)
      .finally(() => setLoading(false));

  const handleSyncCheck = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    syncDatabases(1);
  };
  const handleSyncApply = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    syncDatabases(0);
  };
  const isSelected = (color: ThemeOption) =>
    color === theme ? (
      <i className="fa-thin fa-check" style={{ marginRight: "-16px" }}></i>
    ) : (
      ""
    );
  const selectTheme = (color: ThemeOption) => (e: SyntheticEvent) => {
    e.preventDefault();
    setTheme(env.THEME.setTheme(color));
  };

  return (
    <>
      <NavbarTitle icon="fa-screwdriver-wrench">
        Setări și administrare
      </NavbarTitle>
      <ErrorBox>{error}</ErrorBox>
      <div className="row">
        <div className="col">
          {" "}
          <details role="list">
            <summary aria-haspopup="listbox" style={{ width: "fit-content" }}>
              <i
                className={`fa-fw fa-lg ${
                  currentTheme === "dark"
                    ? "fa-duotone fa-moon-stars"
                    : "fa-light fa-sun-bright"
                }`}
              ></i>
            </summary>
            <ul role="listbox">
              <li>
                <a href="/" onClick={selectTheme("auto")}>
                  {isSelected("auto")}
                  <i className="ms-4 fa-fw fa-duotone fa-circle-half-stroke"></i>{" "}
                  Automat din setări sistem
                </a>
              </li>
              <li>
                <a href="/" onClick={selectTheme("light")}>
                  {isSelected("light")}
                  <i className="ms-4 fa-fw fa-light fa-sun-bright"></i>{" "}
                  Luminoasă
                </a>
              </li>
              <li>
                <a href="/" onClick={selectTheme("dark")}>
                  {isSelected("dark")}
                  <i className="ms-4 fa-fw fa-duotone fa-moon-stars"></i>{" "}
                  Întunecată
                </a>
              </li>
            </ul>
          </details>
        </div>
        <div className="col">
          <Link to="/login" className="float-end">
            <i className="fa-solid fa-arrow-right-from-arc text-warning me-2"></i>
            Deconectare
          </Link>
        </div>
      </div>
      <EmbeddedScannerSwitch />
      <hr />
      <table>
        <thead>
          <tr>
            <td colSpan={6}>
              <nav>
                <ul>
                  <li className="text-center">
                    <a onClick={handleSyncCheck} href="/">
                      <i className="fa-lg fa-light fa-rotate"></i> Caută rânduri
                      adăugate/modificate
                    </a>
                  </li>
                  <li className="text-center">
                    <a onClick={handleSyncApply} href="/">
                      <i className="fa-light fa-gears"></i> Sincronizează
                      diferențele
                    </a>
                  </li>
                </ul>
              </nav>
              {loading && <progress></progress>}
            </td>
          </tr>
          <tr>
            <th>Tabel</th>
            <th>Ultimul ID sincronizat</th>
            <th>Ultima sincronizare</th>
            <th colSpan={2} className="text-center">
              Rânduri adăugate/modificate
            </th>
            <th>Diferențe la data</th>
          </tr>
        </thead>
        <tbody>
          {model.map((table) => (
            <tr key={table.Id}>
              <td>{table.TableName}</td>
              <td>{table.LastInsertId}</td>
              <td>{formatDate(table.LastSyncDate, "long")}</td>
              <td className="text-end">{table.InsertedRows}</td>
              <td>{table.UpdatedRows}</td>
              <td>{formatDate(table.LastSyncCheck, "long")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ConfigUser;
