import { useState } from "react";
import { useParams } from "react-router-dom";
import Scanner from "../../components/Scanner";
import LivrareFacturaList from "../../components/LivrareFacturaList";
import ScannerInput from "../../components/ScannerInput";
import { publish, subscribe } from "../../common/events";
import CamionLivratConsumer from "../../components/CamionLivratConsumer";
import { useModal } from "../../hooks/useModal";
import { useProcesVerbal } from "../../hooks/useProcesVerbal";
import { useScanner } from "../../hooks/useScanner";

const CamionLivratPage = () => {
  const { scanning = false } = useParams();
  const { embeddedScanner } = useScanner();
  const { getProcessVerbal } = useProcesVerbal();
  const [barcode, setBarcode] = useState("");
  const [isScanning, setIsScanning] = useState(
    !getProcessVerbal() && !!scanning && !embeddedScanner
  );
  const [PVSnapshot, setPVSnapshot] = useState(false);
  const { setModal } = useModal();

  const handleSearch = (value: string) => setBarcode(value);
  
  subscribe("CameraScanningChanged", (e) => [
    setBarcode(""),
    setIsScanning(e.detail),
  ]);
  subscribe("ProcesVerbalSnapshot", (e) => [
    setBarcode(""),
    setPVSnapshot(e.detail),
  ]);

  subscribe("FacturaScanComplet", (e) => [
    publish("CameraScanningChanged", false),
    setModal(e.detail, "Scanare completă"),
    setBarcode(""),
  ]);

  return (
    <>
      {isScanning ? (
        <Scanner
          {...{
            handleSearch: setBarcode,
            isScanning,
          }}
        >
          <CamionLivratConsumer barcode={barcode} />
        </Scanner>
      ) : (
        PVSnapshot || (
          <div className="row">
            <CamionLivratConsumer barcode={barcode} />
            <ScannerInput {...{ handleSearch }} />
          </div>
        )
      )}
      <LivrareFacturaList />
    </>
  );
};

export default CamionLivratPage;
